import React, { useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginPage from "./pages/loginpage/LoginPage";
import LandingPage from "./pages/landingpage/LandingPage";
import Staffs from "./pages/Management/staffs/Staffs";
import Roles from "./pages/Management/roles/Roles";
import EditRole from "./pages/Management/roles/EditRole";
import EditStaff from "./pages/Management/staffs/EditStaff";
import Resources from "./pages/Management/resources/Resources";
import ResourceDetail from "./pages/Management/resources/ResourceDetail";
import LanguageSettings from "./pages/settings/languagesettings/LanguageSettings";
import Settings from "./pages/settings/Settings";
import Layout from "./components/layout/Layout";
import UserContext from "./context/userContext";
import { useDispatch } from "react-redux";
import decodeToken from "./utils/decodeToken";
import { loginAction, isLoggedIn } from "./actions/index";
import Logout from "./components/ui/logout/Logout";

const App = () => {
  const context = useContext(UserContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("hotelApp");
    if (token) {
      const decodedToken = decodeToken(token);
      const dateNow = Date.now() / 1000;
      if (decodedToken.exp < dateNow) {
        localStorage.removeItem("hotelApp");
        dispatch(loginAction(null));
        dispatch(isLoggedIn(false));
        context.user = {};
        context.isLogin = false;
      } else {
        dispatch(loginAction(decodedToken));
        dispatch(isLoggedIn(true));
        context.user = decodedToken;
        context.isLogin = true;
      }
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <LoginPage />
          </Route>
          <Route path="/Logout" exact>
            <Logout />
          </Route>
          <Layout>
            <Route path="/LandingPage" exact>
              <LandingPage />
            </Route>
            <Route path="/Staffs">
              <Staffs />
            </Route>
            <Route path="/Roles">
              <Roles />
            </Route>
            <Route path="/EditRole">
              <EditRole />
            </Route>
            <Route path="/EditStaff">
              <EditStaff />
            </Route>
            <Route path="/Resources">
              <Resources />
            </Route>
            <Route path="/ResourceDetail">
              <ResourceDetail />
            </Route>

            <Route path="/Settings">
              <Settings />
            </Route>
          </Layout>
        </Switch>

        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      </BrowserRouter>
    </div>
  );
};

export default App;
