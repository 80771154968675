import React from "react";
import { Link } from "react-router-dom";
import "./CrudOperators.css";

export default function CrudOperators(props) {
  return (
    <div className="CrudContainer">
      <div className="ButtonsContainer">
        <div onClick={props.onClickOpenHandler} className="BackgorundPrimaryColor button-left">
          Add
        </div>
        {props.IsSelected === true ? (
          <>
            <Link
              to={{
                pathname: props.NavlinkAdress,
                state: { id: props.SelectedId },
              }}
              className="BackgorundPrimaryColor button-left">
              Edit
            </Link>
            <div onClick={props.onClickDeleteOpenHandler} className="BackgorundSecondaryColor button-left">Delete</div>
          </>
        ) : (
          <>
            <div className="BackgorundPrimaryColor button-left ButtonDisabled">Edit</div>
            <div className="BackgorundSecondaryColor button-left ButtonDisabled">Delete</div>
          </>
        )}
      </div>
      <div className="ButtonsContainer">
        <div className="SearchContainer">
          <div className="SearchIcon">
            <span className="icon-Search "></span>
          </div>
          <input className="SearchInput" name="Username" />
        </div>
        <div className="BackgroundSecondaryToMiddleGradient button-right">search</div>
      </div>
    </div>
  );
}
