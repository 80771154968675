import React, { useState, useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { isLoggedIn, loginAction } from "../../actions";
import UserContext from "../../context/userContext";
import { loginUser } from "../../Services/userServices";
import "./LoginPage.css";
import CheckLogin from './../../utils/checkLogin';
import decodeToken from "../../utils/decodeToken";

function LoginPage({ history }) {
  const context = useContext(UserContext);
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [, setLoading] = useState(false);
  const [, forceUpdate] = useState();

  const reset = () => {
    setUsername("");
    setPassword("");
  };

  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "Please fill this textbox",
      },
      element: (message) => <div style={{ color: "#ffffff", fontSize: "14px", fontWeight: "bolder" }}>{message}</div>,
    })
  );

  const login = async (e) => {
    e.preventDefault();
    try {
      if (validator.current.allValid()) {
        setLoading("true");

        const { data, status } = await loginUser(username,password);
        if (status === 200) {
          // console.log(result.data.firstName)
          toast.success("You are successfully logged in");
          localStorage.setItem("hotelApp", data.token);
          const decodedToken=await decodeToken(data.token)
          dispatch(loginAction(decodedToken));
          dispatch(isLoggedIn(true));
          context.user=decodedToken
				  context.isLogin=true

          setLoading(false);
          history.replace("/LandingPage");
          reset();
        }
      } else {
        validator.current.showMessages("username");
        forceUpdate(1);
      }
    } catch (ex) {
      toast.error(ex.response.data.ErrorDescription);
      setLoading(false);
    }
  };
  if(CheckLogin()) history.replace('/LandingPage')

  return (
    <div className="FullscrennBackground BackgroundPrimaryToSecondaryGradient">
      <div className="VerticallyAndHorizontallyCentered ColorWhiteColor HorizontallyCenteredText">
        <img className="LogInPicture" src="/images/ic_logo.png" alt="" />
        <div className="LoginPageHeader">Welcome Back</div>
        <div>Login to your account</div>
        <div className="LoginTextBoxContainer">
          <div className="LoginTextBoxBackground"></div>
          <span className="icon icon-user IconCircleBackground"></span>
          <input className="LoginTextBox" type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
          {validator.current.message("username", username, "required")}
        </div>
        <div className="LoginTextBoxContainer">
          <div className="LoginTextBoxBackground"></div>
          <span className="icon icon-lockedFeature IconCircleBackground"></span>
          <input className="LoginTextBox" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          {validator.current.message("password", password, "required")}
        </div>
        {/* <NavLink exact className="BackgorundPrimaryColor button" to="/LandingPage" activeClassName="HeaderNavigation-Active">
          Login
        </NavLink> */}
        <button className="BackgorundPrimaryColor button"  type="submit" onClick={(e) => login(e)}>
          Login
        </button>
      </div>
    </div>
  );
}
export default withRouter(LoginPage);
