import React, { useEffect, useState } from "react";
import ResourcesGridTable from "./ResourcesGridTable";
import { actionGetAllResources } from "../../../actions";

import { useDispatch } from "react-redux";

export default function Resources() {
  useEffect(() => {
    getRoles();
  }, []);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(8);

  const getRoles = async (myPageIndex) => {
    dispatch(actionGetAllResources(1, pageSize));
  };

  return (
    <div className="ContentBody ">
      <div className="BodyPadding">
        <ResourcesGridTable PassPageSize={pageSize} />
      </div>
    </div>
  );
}
