import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { actionGetSelectedResources } from "../../../actions";
import "../../../components/datagridtable/DataGridTable.css";
import Pagination from "../../../components/pagination/Pagination";

function ResourceDetail({ history }) {
  const dispatch = useDispatch();
  const mySelectedResource = useSelector((state) => state.selectedResources);
  const selectedResource = history.location.state.ResourceId;

  useEffect(() => {
    dispatch(actionGetSelectedResources(selectedResource));
  }, []);

  return (
    <>
      <div className="ContentBody ">
        <div className="BodyPadding">
          <div className="GridViewAndPaginationContainer">
            {mySelectedResource.resourceItems ? (
              <div>
                <div className="GridTopTitleAndDescription">
                  <div className="GridTopTitle"> {mySelectedResource.title}</div>
                  <div className="GridTopDescription"> {mySelectedResource.description}</div>
                </div>
                <div className="GridViewAndPaginationContainer">
                  <div className="TableGridViewContainer">
                    <ol className="collection collection-container">
                      <li className="item item-container">
                        <div className="attribute Firstchild">Row</div>
                        <div className="attribute">Resource Item name</div>
                        <div className="attribute">Key</div>
                      </li>

                      {mySelectedResource.resourceItems.map((item, index) => (
                        <li key={index + 1} className="item item-container">
                          <div className="attribute Firstchild" data-name="#">
                            {index + 1}
                          </div>
                          <div className="attribute-container part-id">
                            <div className="attribute" data-name="Role Name">
                              {item.resourceName}
                            </div>
                          </div>
                          <div className="attribute-container part-id">
                            <div className="attribute" data-name="Role Name">
                              {item.resourceKey}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ResourceDetail);
