//peykar bandi haye axios - baraye hameye post ha
// header content-type

import axios from "axios";
// import { toast } from "react-toastify";

axios.defaults.headers.post["Content-Type"] = "application/json";

axios.defaults.headers["Authorization"] = "Bearer "+localStorage.getItem("hotelApp");

//interseptors baraye hameye javab ha (response ha)

// axios.interceptors.response.use(null, error => {
//   if (error.response && error.response.status === 500) {
//     console.log(error);
//     toast.error(" خطا از طرف سرور رخ داده است . لطفا دقایقی دیگر امتحان کنید", {
//       position: "top-right",
//     });
//   }

//   return Promise.reject(error);
// });

const methods = {
  get: axios.get, //restfull api
  post: axios.post,
  put: axios.put,
  delete: axios.delete
};
export default methods;
