import React from "react";
import "./SmallCardView.css";

export default function SmallCardView(props) {
  return (
    <div className="SmallCardContainer">
      <div className="SmallCardDetails">
        <div className="SmallCardTitle">{props.Title}</div>
        <div className="SmallCardDescription">{props.Description}</div>
      </div>

      <div>
        <span className={props.Icon + " SmallCardIcon"}></span>
      </div>
    </div>
  );
}
