import * as React from "react";
import UserContext from "../../context/userContext";

export default function LandingPage() {
  const context = React.useContext(UserContext);
  //  const user = useSelector((state) => state.user);

  return (
    <>
      <div>{context.user.unique_name}</div>
      {/* <div>{user ? user.unique_name : null}</div> */}
    </>
  );
}
