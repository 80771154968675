import http from "./httpServices";
import config from "./config.json";

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙐𝙨𝙚𝙧𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getStaffById = (id) => {
  return http.get(`${config.PardikURL}/Users/${id}`);
};
export const PostAStaff = (fullName, email, password) => {
  return http.post(`${config.PardikURL}/Users`, { fullName, email, password });
};
export const getAllStaffs = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Users/GetAll`, { pageIndex, pageSize });
};
export const deleteStaffById = (id) => {
  return http.delete(`${config.PardikURL}/Users/${id}`);
};
export const PutAssignRoleToStaff = (id, roles) => {
  return http.put(`${config.PardikURL}/Users/AssignRoleToUser/${id}`, { roleIds: roles });
};
export const PutAStaff = (id, fullName, password) => {
  return http.put(`${config.PardikURL}/Users/UpdateUser/${id}`, { fullName, password });
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙤𝙡𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllRoles = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Roles/GetAll`, { pageIndex, pageSize });
};
export const PostARole = (title, description) => {
  return http.post(`${config.PardikURL}/Roles`, { title, description });
};
export const getRoleById = (id) => {
  return http.get(`${config.PardikURL}/Roles/${id}`);
};
export const deleteRoleById = (id) => {
  return http.delete(`${config.PardikURL}/Roles/${id}`);
};
export const PutARole = (id, title, description, resources) => {
  return http.put(`${config.PardikURL}/Roles/${id}`, { title, description, resources: { resourceItemIds: resources } });
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     𝙍𝙚𝙨𝙤𝙪𝙧𝙘𝙚𝙨     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllResources = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Resources/GetAll`, { pageIndex, pageSize });
};
export const getResourceById = (id) => {
  return http.get(`${config.PardikURL}/Resources/${id}`);
};
