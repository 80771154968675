import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { actionGetSelectedUser } from "../../../actions";
import "../../../components/slidingmenu/slidingmenu.css";
import { PutAssignRoleToStaff } from "../../../Services/managementServices";
import { PutAStaff } from "../../../Services/managementServices";
import Loading from "../../../components/ui/loading/Loading";

function EditStaff({ history }) {
  const mySelectedStaff = useSelector((state) => state.selectedUser);
  const selectedCheckBox = history.location.state.id;
  const [currentRoles, setCurrenctRoles] = useState([]);

  const [staffFullname, setStaffFullname] = useState("");
  const [staffPassword, setStaffPassword] = useState("");
  const [staffRepeatPassword, setRepeatPassword] = useState("");

  const [loadingHandler, setLoadingHandler] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actionGetSelectedUser(selectedCheckBox));
  }, []);

  const handleCheckSelectedRoles = (e, selectedRoles) => {
    if (selectedRoles) {
      //------------------
      const copyUserCurrentRoles = [...mySelectedStaff.roles];
      const newRole = copyUserCurrentRoles.find((x) => x.roleId === selectedRoles.roleId);
      newRole.isSelected = e;

      setCurrenctRoles(copyUserCurrentRoles);
      //-----------------
    }
  };

  const updateStaffHandler = async (e) => {
    setLoadingHandler(true);
    updateStaffRolesHandler(e);
    updateStaffInfoHandler(e);
    setTimeout(() => {
      setLoadingHandler(false);
    }, 1500);
  };

  const updateStaffRolesHandler = async (e) => {
    e.preventDefault();
    const selectedRolesId = [];

    currentRoles.length > 0
      ? currentRoles.map((item) => {
          if (item.isSelected) {
            selectedRolesId.push(item.roleId);
          }
        })
      : mySelectedStaff.roles.map((item) => {
          if (item.isSelected) {
            selectedRolesId.push(item.roleId);
          }
        });

    const { status } = await PutAssignRoleToStaff(selectedCheckBox, selectedRolesId);
    if (status === 200) {
      toast.success("Roles Assigned Successfully");
    }
  };

  const updateStaffInfoHandler = async (e) => {
    const fullname = staffFullname ? staffFullname : mySelectedStaff.fullName;
    const password = staffPassword ? staffPassword : mySelectedStaff.password;

    if (staffRepeatPassword === staffPassword) {
      const { status } = await PutAStaff(selectedCheckBox, fullname, password);

      if (status === 200) {
        toast.success("Updated Successfully");
      }
    } else {
      toast.warning("Passwords does not match!");
    }
  };

  return (
    <div className="ContentBody">
      {loadingHandler && <Loading />}

      {mySelectedStaff ? (
        <div className="BodyPadding">
          <form className="EditPageTwoColumn">
            <div className="EditPageTwoColumnItem">
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">Staff Fullname</div>
                <input className="SlidingMenuTextBox" value={staffFullname ? staffFullname : mySelectedStaff.fullName} onChange={(e) => setStaffFullname(e.target.value)} id="staffFullname" />
                <div className="SlidingMenuMoreDetail">Fullname of the the staff.</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">Staff Email</div>
                <input className="SlidingMenuTextBox" disabled id="StaffEmail" value={mySelectedStaff.email} />
                <div className="SlidingMenuMoreDetail">Email description</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">Staff Password</div>
                <input className="SlidingMenuTextBox" id="StaffPassword" type="password" value={staffPassword ? staffPassword : null} onChange={(e) => setStaffPassword(e.target.value)} />
                <div className="SlidingMenuMoreDetail">Only write password if you want to change it.</div>
              </div>
              <div className="FullWidthContainer">
                <div className="SlidingMenuItemTitle">Repeat Password</div>
                <input className="SlidingMenuTextBox" id="StaffRepeatPassword" type="password" value={staffRepeatPassword ? staffRepeatPassword : null} onChange={(e) => setRepeatPassword(e.target.value)} />
                <div className="SlidingMenuMoreDetail">repeat the password to confirm the change.</div>
              </div>
            </div>
            
            <div className="EditPageTwoColumnItem">
              <div className="EditPageItemBorderMediumDescription">
                <div className="EditTitle">Role Assignment</div>
                <div className="EditDescriptions">You can assigned the roles you desire to this staff to define access and permission levels to use the panel</div>
              </div>
              <div className="EditPageItemBorderMedium">
                <div className="EditStaffPageItemHeader">
                  <div className="SlidingMenuTitle">Roles</div>
                </div>
                {mySelectedStaff.roles
                  ? mySelectedStaff.roles.map((item, index) => (
                      <div key={index} className="EditStaffPageColomnSelectableItem">
                        <input className="Checkbox" type="checkbox" name="" id={index} onChange={(e) => handleCheckSelectedRoles(e.target.checked, item)} checked={item.isSelected} />
                        <div>{item.title}</div>
                      </div>
                    ))
                  : null}
              </div>
            </div>

            <div className="SlidingMenuFooterContainer FullWidthContainer">
              <div className="EditPgefooter">
                {staffFullname === null ? (
                  <div disabled className="SlidingMenuSavebutton">
                    Save
                  </div>
                ) : (
                  <div className="SlidingMenuSavebutton" onClick={(e) => updateStaffHandler(e)}>
                    Save
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
}
export default withRouter(EditStaff);
