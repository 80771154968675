import http from "./httpServices";
import config from "./config.json";

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Announcements     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑

export const getAllAnnouncements = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Announcements/GetAll`, { pageIndex, pageSize });
};
export const getAnnouncementById = (id) => {
  return http.get(`${config.PardikURL}/Announcements/${id}`);
};
export const PostAnAnnouncement = (title, languageId, timeDescription, locationDescription, description, isComingSoon, expireDate, imageKey) => {
  return http.post(`${config.PardikURL}/Announcements`, { title, languageId, timeDescription, locationDescription, description, isComingSoon, expireDate, imageKey });
};
export const deleteAnnouncementById = (id) => {
  return http.delete(`${config.PardikURL}/Announcements/${id}`);
};
export const PutAnAnnouncement = (id, expireDate, isComingSoon, imageKey, isActive) => {
  return http.put(`${config.PardikURL}/Announcements/${id}`, { expireDate, isComingSoon, imageKey, isActive });
};
export const PutAnAnnouncementItemAdd = (id, title, description, timeDescription, locationDescription, languageId) => {
  return http.put(`${config.PardikURL}/Announcements/AddItem/${id}`, { title, description, timeDescription, locationDescription, languageId });
};
export const PutAnAnnouncementItemEdit = (id, languageId, title, description, timeDescription, locationDescription) => {
  return http.put(`${config.PardikURL}/Announcements/UpdateItem/${id}/${languageId}`, { title, description, timeDescription, locationDescription });
};
export const deleteAnnouncementItemById = (id, languageId) => {
  return http.delete(`${config.PardikURL}/Announcements/DeleteItem/${id}/${languageId}`);
};

//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Hotel Services     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllHotelServices = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/HotelService/GetAll`, { pageIndex, pageSize });
};
export const getHotelServiceById = (id) => {
  return http.get(`${config.PardikURL}/HotelService/${id}`);
};
export const PostAHotelService = (serviceTitle, serviceIcon, startTime, endTime, languageId) => {
  return http.post(`${config.PardikURL}/HotelService`, { serviceTitle, serviceIcon, startTime, endTime, languageId });
};
export const deleteAHotelServiceById = (id) => {
  return http.delete(`${config.PardikURL}/HotelService/${id}`);
};
export const PutAHotelService = (id, serviceTitle, serviceIcon, startTime, endTime, isActive) => {
  return http.put(`${config.PardikURL}/HotelService/${id}`, { serviceTitle, serviceIcon, startTime, endTime, isActive });
};
export const PostAHotelServiceItemAdd = (id, serviceTitle, languageId) => {
  return http.put(`${config.PardikURL}/HotelService/AddNewItem/${id}`, { serviceTitle, languageId });
};
export const PutAHotelServiceItemAdd = (id, languageId, serviceTitle) => {
  return http.put(`${config.PardikURL}/HotelService/UpdateItem/${id}/${languageId}`, { serviceTitle });
};
export const deleteAHotelServiceItemById = (id, languageId) => {
  return http.delete(`${config.PardikURL}/HotelService/DeleteItem/${id}/${languageId}`);
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Foods Section     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Meal Time     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllMealTimes = () => {
  return http.get(`${config.PardikURL}/MealTime`);
};
export const getMealTimeByid = (id) => {
  return http.get(`${config.PardikURL}/MealTime/GetById/${id}`);
};
export const PostAMealTime = (title, startTime, endTime, languageId) => {
  return http.post(`${config.PardikURL}/MealTime`, { title, startTime, endTime, languageId });
};
export const PutAMealTime = (id, title, startTime, endTime) => {
  return http.put(`${config.PardikURL}/MealTime/${id}`, { title, startTime, endTime });
};
export const PostAMealTimeItem = (id, title, languageId) => {
  return http.put(`${config.PardikURL}/MealTime/AddNewItem/${id}`, { title, languageId });
};
export const PutAMealTimeItem = (id, languageId, title) => {
  return http.put(`${config.PardikURL}/MealTime/UpdateItem/${id}/${languageId}`, { title });
};
export const DeleteAMealTime = (id) => {
  return http.delete(`${config.PardikURL}/MealTime/${id}`);
};
export const DeleteAMealTimeItem = (id, languageId) => {
  return http.delete(`${config.PardikURL}/MealTime/DeleteItem/${id}/${languageId}`);
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Food Category     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllFoodCategories = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/FoodCategories/GetAll`, { pageIndex, pageSize });
};
export const getFoodCategoryById = (id) => {
  return http.get(`${config.PardikURL}/FoodCategories/${id}`);
};
export const PostAFoodCategory = (title, languageId) => {
  return http.post(`${config.PardikURL}/FoodCategories`, { title, languageId });
};
export const PutAFoodCategory = (id, title, isActive) => {
  return http.put(`${config.PardikURL}/FoodCategories/${id}`, { title, isActive });
};
export const PostAFoodCategoryItem = (id, title, languageId) => {
  return http.put(`${config.PardikURL}/FoodCategories/AddNewItem/${id}`, { title, languageId });
};
export const PutAFoodCategoryItem = (id, languageId, title) => {
  return http.put(`${config.PardikURL}/FoodCategories/UpdateItem/${id}/${languageId}`, { title });
};
export const DeleteAFoodCategory = (id) => {
  return http.delete(`${config.PardikURL}/FoodCategories/${id}`);
};
export const DeleteAFoodCategoryItem = (id, languageId) => {
  return http.delete(`${config.PardikURL}/FoodCategories/DeleteItem/${id}/${languageId}`);
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Food Menu     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllFoods = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/Foods/GetAll`, { pageIndex, pageSize });
};
export const getFoodById = (id) => {
  return http.get(`${config.PardikURL}/Foods/${id}`);
};
export const PostAFood = (title, description, foodCategoryId, mealTimeId, price, imageKey, languageId) => {
  return http.post(`${config.PardikURL}/Foods`, { title, description, foodCategoryId, mealTimeId, price, imageKey, languageId });
};
export const PutAFood = (id, title, foodCategoryId, mealTimeId, price, imageKey, isActive) => {
  return http.put(`${config.PardikURL}/Foods/${id}`, { title, foodCategoryId, mealTimeId, price, imageKey, isActive });
};
export const PostAFoodItem = (id, title, description, languageId) => {
  return http.put(`${config.PardikURL}/Foods/AddNewItem/${id}`, { title, description, languageId });
};
export const PutAFoodItem = (id, languageId, title, description) => {
  return http.put(`${config.PardikURL}/Foods/UpdateItem/${id}/${languageId}`, { title, description });
};
export const DeleteAFood = (id) => {
  return http.delete(`${config.PardikURL}/Foods/${id}`);
};
export const DeleteFoodItem = (id, languageId) => {
  return http.delete(`${config.PardikURL}/Foods/DeleteItem/${id}/${languageId}`);
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     Mirror Compliments     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAllMirrorCompliments = (pageIndex, pageSize) => {
  return http.post(`${config.PardikURL}/UniversalCompliments/GetAll`, { pageIndex, pageSize });
};
export const getMirrorComplimentById = (id) => {
  return http.get(`${config.PardikURL}/UniversalCompliments/${id}`);
};
export const PostAMirrorCompliment = (title, languageId, expireDate) => {
  return http.post(`${config.PardikURL}/UniversalCompliments`, { title, languageId, expireDate });
};
export const PutAMirrorCompliment = (id, title, expireDate, isActive) => {
  return http.put(`${config.PardikURL}/UniversalCompliments/${id}`, { title, expireDate, isActive });
};
export const PostAMirrorComplimentItem = (id, text, languageId) => {
  return http.put(`${config.PardikURL}/UniversalCompliments/AddNewItem/${id}`, { text, languageId });
};
export const PutAMirrorComplimentItem = (id, languageId, text) => {
  return http.put(`${config.PardikURL}/UniversalCompliments/UpdateItem/${id}/${languageId}`, { text });
};
export const DeleteAMirrorCompliment = (id) => {
  return http.delete(`${config.PardikURL}/UniversalCompliments/${id}`);
};
export const DeleteMirrorComplimentItem = (id, languageId) => {
  return http.delete(`${config.PardikURL}/UniversalCompliments/DeleteItem/${id}/${languageId}`);
};
//⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑     About Hotel     ⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑⁑
export const getAboutHotel = () => {
  return http.get(`${config.PardikURL}/AboutHotel/get`);
};
export const PostAboutHotelLogoAndAmenities = (logoFileKey, hotelAmenities) => {
  return http.post(`${config.PardikURL}/AboutHotel/UpsertHotelInformationLogoAndAmenities`, {logoFileKey,hotelAmenities});
};
