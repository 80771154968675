import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import UserContext from "../../context/userContext";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import "./Layout.css";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import CheckLogin from "../../utils/checkLogin";

function Layout(props) {
  const context = React.useContext(UserContext);

  const [managmentOpen, setmanagmentOpen] = React.useState();
  const [provisionOpen, setprovisionOpen] = React.useState();

  if (!CheckLogin()) props.history.replace("/");

  const openManagment = () => {
    setmanagmentOpen(!managmentOpen);
  };
  const openMirrorSection = () => {
    setprovisionOpen(!provisionOpen);
  };

  return (
    <div>
      <div className="LayoutContainer">
        <div className="SideBar">
          <div className="HotelInformationContainer">
            <img className="SidebarTitleImage" src="/images/ic_logo.png" alt="" />
            <div className="SidebarTitle">{context.user.unique_name}</div>
          </div>

          <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
            {managmentOpen ? (
              <ListItemButton className="MuiListCssSelected" onClick={openManagment}>
                <ListItemIcon>
                  <span className="icon-Management ColorWhiteColor LayoutListIcon"></span>
                </ListItemIcon>
                <ListItemText primary="Management" />
                <span className="icon-DropUpArrow ColorWhiteColor"></span>
              </ListItemButton>
            ) : (
              <ListItemButton onClick={openManagment}>
                <ListItemIcon>
                  <span className="icon-Management ColorWhiteColor LayoutListIcon"></span>
                </ListItemIcon>
                <ListItemText primary="Management" />
                <span className="icon-DropDownArrow ColorWhiteColor"></span>
              </ListItemButton>
            )}

            <Collapse in={managmentOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton className="MuiSubListCss">
                  <NavLink exact className="NavLinkWidth" to="/Staffs" activeClassName="HeaderNavigation-Active">
                    <ListItemText primary="Staffs" sx={{ pl: 8 }} />
                  </NavLink>
                </ListItemButton>
                <ListItemButton className="MuiSubListCss">
                  <NavLink exact className="NavLinkWidth" to="/Roles" activeClassName="HeaderNavigation-Active">
                    <ListItemText primary="Roles" sx={{ pl: 8 }} />
                  </NavLink>
                </ListItemButton>
                <ListItemButton className="MuiSubListCss">
                  <NavLink exact className="NavLinkWidth" to="/Resources" activeClassName="HeaderNavigation-Active">
                    <ListItemText primary="Resources" sx={{ pl: 8 }} />
                  </NavLink>
                </ListItemButton>
              </List>
            </Collapse>
          </List>




          <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
            {provisionOpen ? (
              <ListItemButton className="MuiListCssSelected" onClick={openMirrorSection}>
                <ListItemIcon>
                  <span className="icon-provision ColorWhiteColor LayoutListIcon"></span>
                </ListItemIcon>
                <ListItemText primary="Provisions" />
                <span className="icon-DropUpArrow ColorWhiteColor"></span>
              </ListItemButton>
            ) : (
              <ListItemButton onClick={openMirrorSection}>
                <ListItemIcon>
                  <span className="icon-provision ColorWhiteColor LayoutListIcon"></span>
                </ListItemIcon>
                <ListItemText primary="Provisions" />
                <span className="icon-DropDownArrow ColorWhiteColor"></span>
              </ListItemButton>
            )}

            <Collapse in={provisionOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton className="MuiSubListCss">
                  <NavLink exact className="NavLinkWidth" to="/Devices" activeClassName="HeaderNavigation-Active">
                    <ListItemText primary="Devices" sx={{ pl: 8 }} />
                  </NavLink>
                </ListItemButton>
                <ListItemButton className="MuiSubListCss">
                  <NavLink exact className="NavLinkWidth" to="/ProductTypes" activeClassName="HeaderNavigation-Active">
                    <ListItemText primary="ProductTypes" sx={{ pl: 8 }} />
                  </NavLink>
                </ListItemButton>
                <ListItemButton className="MuiSubListCss">
                  <NavLink exact className="NavLinkWidth" to="/Features" activeClassName="HeaderNavigation-Active">
                    <ListItemText primary="Features" sx={{ pl: 8 }} />
                  </NavLink>
                </ListItemButton>
              </List>
            </Collapse>
          </List>




         
          <div className="LayoutSideBarFooterContainer">
            <List className="MuiListCss" sx={{ width: "100%" }} component="nav">
              <NavLink exact to="/Settings">
                <ListItemButton className="MuiListCssSelected">
                  <ListItemIcon>
                    <span className="icon-settings ColorWhiteColor LayoutListIcon"></span>
                  </ListItemIcon>

                  <ListItemText primary="Settings" sx={{ pl: 1 }} />
                </ListItemButton>
              </NavLink>
            </List>
          </div>
        </div>

        <div className="HeaderandContentContainert">
          <div className="LayoutHeader VerticallyCenteredObject">
            <div className="HeaderBarCss">
              <div className="DisplayFlex">
                {/* <Breadcrumbs status="BreadcrumbMain" title="Main" />
                <Breadcrumbs status="BreadcrumbParrent" title="Parrent" />
                <Breadcrumbs status="BreadcrumbChild" title="Child" /> */}
              </div>
              <div className="HeaderBarIconsContainer">
                <div className="HeaderBarIcons">
                  <div className="NotificationAmountContainer">5</div>
                  <span className="icon-notification "></span>
                </div>
                <div className="HeaderBarIcons">
                  <div className="NotificationAmountContainer">5</div>
                  <span className="icon-notification "></span>
                </div>
                <div className="HeaderBarIcons">
                  <NavLink exact to="/Logout">
                    <span className="icon-exit"></span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="LayoutContent BackgroundLightToWhiteGradient">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Layout);
