import {useContext} from 'react';
import { withRouter } from "react-router-dom";

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { isLoggedIn, logoutAction } from '../../../actions';
import UserContext from '../../../context/userContext';

const Logout = ({history}) => {

    const context = useContext(UserContext)
    const dispatch=useDispatch()

    const token=localStorage.getItem("hotelApp")
    if(token){
        localStorage.removeItem("hotelApp")

        dispatch(logoutAction(null))
        dispatch(isLoggedIn(false))
        context.user = {};
        context.isLogin = false;

        toast.success("You Logged out Successfully", {
            position: "top-right",
            closeOnClick: "true",
            theme: "dark",
          });
          history.replace("/") 
    }
    else{
        history.replace("/")
    }
    

    return null;
}

 
export default withRouter(Logout);