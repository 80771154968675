import { useContext } from 'react';
import UserContext from './../context/userContext';
import { useDispatch } from 'react-redux';
import decodeToken from './decodeToken';
import { loginAction, isLoggedIn } from './../actions/index';

const CheckLogin = () => {

  const context = useContext(UserContext);
  const dispatch=useDispatch()

  let isLogin=false
  const token = localStorage.getItem('hotelApp');
  if (token) {
      const decodedToken = decodeToken(token);
      const dateNow = Date.now() / 1000;
      if (decodedToken.exp < dateNow) {
          localStorage.removeItem('hotelApp');
          dispatch(loginAction(null))
          dispatch(isLoggedIn(false))
          context.user = {};
          context.isLogin = false;
          isLogin=false
      } else {
        dispatch(loginAction(decodedToken))
        dispatch(isLoggedIn(true))
          context.user = decodedToken;
          context.isLogin = true;
          isLogin=true
      }
  }
  return isLogin;

};

export default CheckLogin;

